import request from '../../util/loginrequest'
// 1.注册接口
export const toRegistered = (params) => {
  return request({

    url: '/api/demo/Register/register',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      mobile: params.mobile || '',
      user_email: params.user_email || '',
      user_pass: params.user_pass || '',
      job: params.job,
      code: params.code
    }

  })
}
// 1.忘记密码
export const forgetPass = (params) => {
  return request({

    url: 'api/demo/Register/forgetPass',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_login: params.user_login || '',
      user_pass: params.user_pass || '',
      code: params.code
    }

  })
}
// 获取短信验证码
export const getSMScode = (params) => {
  return request({
    url: '/api/demo/Register/aliyun',
    method: 'post',
    data: {
      phone: params
    }

  })
}
