<template>
  <div class="login-main">
    <div class="login-left">
      <div class="login-title">
        重置密码
      </div>
      <div class="henxian"></div>
      <div class="form">
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">手机号</div>
              <div class="redstar">
                *
              </div>
              <div class="errmessage">
                {{usermobileerrmessage}}
              </div>
            </label>
          </div>
          <input
            type="text"
            @focus="getfocus($event,'usermobile')"
            @blur="getblur($event,'usermobile')"
            v-model="usermobile"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
          >
        </div>
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">验证码</div>
              <div class="redstar">
                *
              </div>
            </label>
          </div>
          <input
            id="code"
            type="text"
            @focus="getfocus($event,'usercode')"
            @blur="getblur($event,'usercode')"
            v-model="usercode"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="6"
          >
          <div class="sendconde">
            <span
              v-show="show"
              @click="tosendthecode"
            >发送验证码</span>
            <span
              v-show="!show"
              class="count"
            >{{count}} s</span>
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">新密码</div>
              <div class="redstar">
                *
              </div>
              <div class="errmessage">
                {{userpassworderrmessage}}
              </div>
            </label>
          </div>
          <input
            type="password"
            @focus="getfocus($event,'userpassword')"
            @blur="getblur($event,'userpassword')"
            v-model="userpassword"
          >
        </div>
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">确认密码</div>
              <div class="redstar">
                *
              </div>
              <div class="errmessage">
                {{userconfirmpassworderrmessage}}
              </div>
            </label>
          </div>
          <input
            type="password"
            @focus="getfocus($event,'userconfirmpassword')"
            @blur="getblur($event,'userconfirmpassword')"
            v-model="userconfirmpassword"
          >
        </div>
      </div>
      <div class="submit">
        <div
          class="submit-left"
          @click="toresetpassword()"
        >
          提交
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSMScode, forgetPass } from '../../../api/login/resetpassword'
export default {
  data () {
    return {
      // 用户手机号
      usermobile: '',
      // 用户验证码
      usercode: '',
      // 用户密码
      userpassword: '',
      // 用户确认密码
      userconfirmpassword: '',
      // 手机号错误信息
      usermobileerrmessage: '',
      // 邮箱错误信息
      useremailerrmessage: '',
      // 密码错误信息
      userpassworderrmessage: '',
      // 确认密码错误信息
      userconfirmpassworderrmessage: '',
      // 倒计时实现
      show: true,
      count: '',
      timer: null

    }
  },
  methods: {
    toLogin () {
      this.$router.push({
        path: 'login'
      })
    },
    getfocus (event, str) {
      event.currentTarget.previousElementSibling.classList.add('gd')
      const that = this
      if (str === 'usermobile') {
        that.$data.usermobileerrmessage = ''
      } else if (str === 'useremail') {
        that.$data.useremailerrmessage = ''
      } else if (str === 'userpassword') {
        that.$data.userpassworderrmessage = ''
      } else if (str === 'userconfirmpassword') {
        that.$data.userconfirmpassworderrmessage = ''
      }
    },
    getblur (event, str) {
      const that = this
      if (str === 'usermobile') {
        if (that.$data.usermobile === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        } else {
          const regMobile = /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
          if (regMobile.test(that.$data.usermobile)) {

          } else {
            that.$data.usermobileerrmessage = '手机号格式错误'
          }
        }
      } else if (str === 'usercode') {
        if (that.$data.usercode === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        }
      } else if (str === 'userpassword') {
        if (that.$data.userpassword === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        } else {
          const regPassWord = /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,20}$/
          if (regPassWord.test(that.$data.userpassword)) {

          } else {
            that.$data.userpassworderrmessage = '密码至少包含数字跟字母'
          }
        }
      } else if (str === 'userconfirmpassword') {
        if (that.$data.userpassword === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        } else {
          if (that.$data.userpassword === that.$data.userconfirmpassword) {

          } else {
            that.$data.userconfirmpassworderrmessage = '两次密码输入必须一致'
          }
        }
      }
    },
    // 获取短信验证码
    tosendthecode () {
      const that = this
      if (this.$data.usermobile === '') {
        this.$message.error('请填写手机号')
      } else {
        getSMScode(that.$data.usermobile).then(res => {
          that.$message({
            message: '验证码发送成功',
            type: 'success'
          })
          const TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.show = false
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
          console.log('短信验证码发送成功', res)
        }, err => {
          that.$message.error('验证码发送失败')
          console.log('短信验证码发送失败', err)
        })
      }
    },
    // 提交用户注册信息
    toresetpassword () {
      const that = this
      // eslint-disable-next-line eqeqeq
      if (that.$data.usermobileerrmessage == '' && that.$data.useremailerrmessage == '' && that.$data.userpassworderrmessage == '' && that.$data.userconfirmpassworderrmessage == '' && that.$data.usermobile !== '' && that.$data.usercode !== '' && that.$data.userpassword !== '' && that.$data.userconfirmpassword !== '') {
        const params = {
          user_login: that.$data.usermobile,
          user_pass: that.$data.userpassword,
          code: that.$data.usercode
        }
        forgetPass(params).then(res => {
          if (res.code === 1) {
            that.$message({
              message: '重置密码成功',
              type: 'success'
            })
            setTimeout(() => {
              that.$router.push('login')
            }, 1000)
          } else {
            console.log(res)
            that.$message.error(res.data)
          }
        }, err => {
          console.log(err)
        })
      } else {
        this.$message({
          message: '请正确填写信息后重试',
          type: 'warning'
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.gd {
  transform: translateY(-10px);
}
.login-main {
  margin: 0 auto;
  width: 860px;
  height: 787.5px;
  background-color: #fff;
  margin-top: 82px;
  margin-bottom: 122px;
  display: flex;
  .login-left {
    width: 834px;
    box-sizing: border-box;
    padding: 125px 92px;
    .login-title {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      margin-bottom: 4px;
    }
    .henxian {
      width: 35px;
      height: 5px;
      background-color: #661392;
      margin-bottom: 105px;
    }
    .form {
      width: 665px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .form-item {
        width: 292px;
        border-bottom: 1px solid #b9b9b9;
        margin-bottom: 50px;
        label {
          position: relative;
          display: flex;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #b9b9b9;
          margin-bottom: 11px;
          .text {
            margin-right: 6px;
          }
          .redstar {
            color: #f00;
          }
          .errmessage {
            position: absolute;
            right: 0px;
            color: red;
          }
        }
        input {
          width: 100%;
          border: 0px;
          outline: 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #000000;
        }
        .sendconde {
          cursor: pointer;
          display: inline-block;
          float: right;
          color: #2a74fe;
          padding: 5px;
          background-color: #e6efff;
          transform: translateY(-6px);
          .count {
            display: inline-block;
            width: 70px;
            text-align: center;
            background-color: #e6efff;
          }
        }
        #code {
          width: 50%;
        }
      }
    }
    .submit {
      margin-top: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .submit-left {
        cursor: pointer;
        width: 160px;
        height: 60px;
        background: #5e068c;
        opacity: 1;
        border-radius: 4px;
        color: #fff;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .submit-right {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 31px;
        color: #b9b9b9;
        span {
          cursor: pointer;
          color: #990263;
        }
      }
    }
  }
}
</style>
